@tailwind base;

.fadein {
    opacity: 0;
    -moz-transition: opacity 1s;
    -webkit-transition: opacity 1s;
    -o-transition: opacity 1s;
    transition: opacity 1s;
}

body {
    background: url('./interlaced.png')
}

body.loaded .fadein {
    opacity: 1;
}

@tailwind components;
@tailwind utilities;